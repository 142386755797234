<template>
  <div class="line-wishes-page">
    <img class="love-girl" src="@/assets/website-use/love.png" alt="LoveGirl" />
    <img
      class="need-girl"
      src="@/assets/website-use/needThisCoolGuy.png"
      alt="NeedThisCoolGuy"
    />
    <p class="title">我要許願</p>
    <div class="tw-container">
      <!-- 商品名稱 -->
      <div class="form-floating mb-3">
        <input
          name="name"
          type="text"
          class="form-control"
          id="Name"
          placeholder="商品名稱(必填)"
          maxlength="30"
          required
          v-model="wishData.name"
        />
        <label class="text-danger" for="Name">商品名稱(必填)</label>
      </div>
      <!-- 產地國家 -->
      <div class="form-floating mb-3">
        <input
          name="countries"
          type="text"
          class="form-control"
          id="Nation"
          placeholder="產地國家(非必填)"
          maxlength="12"
          v-model="wishData.country"
        />
        <label for="Nation">產地國家(非必填)</label>
      </div>
      <!-- 描述 -->
      <div class="form-floating">
        <textarea
          name="description"
          class="form-control"
          placeholder=""
          id="Description"
          maxlength="250"
          style="height: 100px"
          v-model="wishData.description"
        ></textarea>
        <label for="Description">描述(非必填)</label>
      </div>
      <hr />
      <!-- 商品照片 -->
      <div class="mb-3">
        <label for="formFile" class="form-label text-danger"
          >商品照片(必填)</label
        >
        <input
          id="ImageFile"
          class="form-control"
          type="file"
          required
          accept="image/jpeg, image/png"
          @change="listenImg"
        />
      </div>
      <!-- 許願 -->
      <div class="text-center">
        <img
          class="addOneA"
          src="@/assets/website-use/addOneA.png"
          alt="addOneA"
        />
        <button
          id="SubmitBtn"
          class="d-none submit"
          @click="createWish"
          type="button"
        >
          許願
        </button>
        <img
          class="addOneB"
          src="@/assets/website-use/addOneB.png"
          alt="addOneB"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      groupLinkId: null,
      lineUserId: null,
      wishData: {
        name: '',
        country: '',
        description: '',
      },
      newImg: null,
    }
  },
  created() {
    console.log(this.$route)
    if (!this.$route.query.groupLinkId) return alert('許願頁面尚未有群組關聯 ID')
    this.groupLinkId = this.$route.query.groupLinkId
  },
  mounted() {
    this.initLineSdk()
  },
  methods: {
    initLineSdk() {
      const vm = this
      liff.init({
        liffId: process.env.VUE_APP_WISHES_PAGE_LINE_LIFFID
      }).then(() => {
        console.log('LIFF init');
        const context = liff.getContext();
        if (context.type !== 'external') vm.lineUserId = context.userId;
        $('#SubmitBtn').removeClass('d-none')
      }).catch((error) => {
        console.log(error);
        vm.error = error
        alert('尚未取得您 Line 的相關資訊')
      });
    },
    listenImg() {
      this.newImg = null
      const imageFile = $('#ImageFile')[0].files[0]
      const rendom = Math.random().toString().split(".")
      this.newImg = new File([imageFile], `${rendom[1]}.${imageFile.name.split(".")[1]}`, {
        type: imageFile.type,
      })
    },
    createWish() {
      const vm = this
      if (!this.groupLinkId && !this.lineUserId) return alert('請透過 Line APP 群組開啟此頁面')
      if (!this.wishData.name) return alert('您尚未輸入商品名稱')
      if (!this.newImg) return alert('您尚未上傳圖片')
      this.$methods.switchLoading('show')
      const createWishApi = `${process.env.VUE_APP_API}/apps/line/createWishMerchandise`
      const data = new FormData()
      data.append('groupLinkId', this.groupLinkId)
      data.append('lineUserId', this.lineUserId)
      data.append('names', this.wishData.name)
      data.append('countries', this.wishData.country)
      data.append('descriptions', this.wishData.description)
      data.append('imageFiles', this.newImg)
      $.ajax({
        type: 'POST',
        async: true,
        url: createWishApi,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res)
          if (res.code === '200') {
            vm.$methods.switchLoading('hide')
            alert('成功')
            window.close()
          }
        },
        error: function (err) {
          console.log(err)
          vm.$methods.switchLoading('hide')
          alert(err.message)
        },
      })
    },
  },
}
</script>